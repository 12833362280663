import React, { useContext } from 'react';
import { Thumbnail } from '@andes/thumbnail';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import TracksContext from '../../context/tracks-context';

const { supportsWebShareApi } = require('../../helpers/dom-utils');

const ShareButton = props => {
  const { tracks } = props;
  const hasSupportsWebShareApi = supportsWebShareApi();
  const { trackMelidata } = useContext(TracksContext.Consumer);

  const handlerActionShared = () => {
    tracks.forEach(track => {
      trackMelidata(track.data.path, track.data.eventData);
    });
    try {
      const shareUrl = new URL(document.location.href);
      shareUrl.searchParams.delete('accessToken');
      if (hasSupportsWebShareApi) {
        navigator.share({
          text: document.title,
          url: shareUrl.toString(),
        });
      } else {
        window.MobileWebKit.executeNative({
          method: 'share_sheet',
          args: {
            title: document.title,
            data: shareUrl.toString(),
          },
        });
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  return (
    <div className="share-button-container">
      <Thumbnail className="action-shared">
        <Icon color="black" type="Share24" onClick={handlerActionShared} />
      </Thumbnail>
    </div>
  );
};

ShareButton.propTypes = {
  tracks: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        path: PropTypes.string,
        eventData: PropTypes.shape({}),
      }),
    }),
  ),
};

export default ShareButton;
