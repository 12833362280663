/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getParams, useQueryParams } from '../../hooks/useQueryParams';
import { NAME_CONFIG_KEYS } from '../../helpers/constants';

import Fallback from '../Fallback';

const { CheckboxList } = require('@andes/checkbox-list');
const { ListItem, ListGroup } = require('@andes/list');

const FilterView = props => {
  const { brick, className, performEvent, fallback } = props;
  const [filterSearch, setFilterSearch] = useState(new Set());
  const { getQuery, updateQuery, deleteQuery } = useQueryParams();

  const handleEvent = () => {
    if (performEvent) {
      const requestBrick = [
        {
          type: 'update_bricks',
          data: {
            bricks: [
              {
                id: 'loading_desktop',
                uiType: 'loading',
                data: {
                  loadingStatus: true,
                  modifier: 'inline',
                  size: 'medium',
                },
              },
            ],
          },
        },
        {
          type: 'request',
          data: {
            key: NAME_CONFIG_KEYS.SEARCH_CARD,
            arguments: getParams(),
          },
        },
      ];
      requestBrick.forEach(event => performEvent(event));
    }
  };

  const handleOnClick = (e, value) => {
    if (!filterSearch.has(value)) {
      filterSearch.add(value);
    } else {
      filterSearch.delete(value);
    }
    const arr = Array.from(filterSearch);
    updateQuery([
      { key: 'filters', value: arr.join('|') },
      { key: 'page', value: 1 },
      { key: 'offset', value: 0 },
      { key: 'limit', value: 18 },
    ]);
    if (arr.length === 0) deleteQuery('filters');
    handleEvent();
  };

  useEffect(() => {
    const currentFilters = getQuery('filters');
    const currentSearch = getQuery('q');
    const offset = getQuery('offset');
    const limit = getQuery('limit');
    const params = [];
    const sorts = getQuery('sort');
    if (currentSearch) params.push({ key: 'q', value: currentSearch });
    if (sorts) params.push({ key: 'sort', value: sorts });
    if (!offset) params.push({ key: 'offset', value: 0 });
    if (!limit) params.push({ key: 'limit', value: 18 });
    if (params.length > 0) updateQuery(params);
    if (currentFilters || currentSearch) {
      if (currentFilters) setFilterSearch(new Set(currentFilters.split('|')));
    }
    window.addEventListener('locationchange', () => {
      setFilterSearch(new Set());
    });
    handleEvent();
  }, []);

  if (fallback) {
    return (
      <div className={className}>
        <Fallback {...fallback} />
      </div>
    );
  }

  return (
    <div className={className}>
      <div className={`${className}-filter`}>
        <CheckboxList
          id="filter-search"
          srLabel="filter-search"
          key="filter-search"
          onClick={handleOnClick}
          className={`${className}-filter-check`}
          value={Array.from(filterSearch)}
        >
          {brick?.bricks.map(({ data }) => (
            <ListGroup
              label={data?.label}
              id={data?.id}
              key={data?.id}
              aria-labelledby={data?.label}
              aria-label={data?.label}
            >
              {data?.fallback ? <Fallback {...data?.fallback} /> : <></>}
              {data?.values?.map(({ id, name }) => (
                <ListItem
                  value={id}
                  title={name}
                  key={id}
                  aria-labelledby={id}
                  aria-label={name}
                />
              ))}
            </ListGroup>
          ))}
        </CheckboxList>
      </div>
    </div>
  );
};
FilterView.defaultProps = {
  className: 'sc-filter-left',
  title: '',
  brick: {
    bricks: [],
  },
};

FilterView.propTypes = {
  className: PropTypes.string,
  brick: PropTypes.shape(PropTypes.arrayOf(PropTypes.shape({}))),
  performEvent: PropTypes.func,
  fallback: PropTypes.shape(),
  events: PropTypes.arrayOf(PropTypes.shape({})),
};

export default FilterView;
