/* eslint-disable nordic/i18n-jsx-no-literals */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@andes/button';
import FloatingMenu, { FloatingMenuItem, FloatingMenuGroup } from '@andes/floating-menu';
import { Pill } from '@andes/badge';
import { useSelector } from 'react-redux';
import { Image } from 'nordic/image';
import Icon from '../Icon';
import { handlerLogin, handlerLogout } from '../../helpers/actionsAuth';
import { getContext } from '../../helpers/selectors';

const User = (props) => {
  const { cta, icon, nickName, className, image, userName, labelNavigation, navigation, performEvent } = props;
  const { platform, fullUrl, auth } = useSelector(getContext);
  const [logout, setLogout] = useState(false);
  const errorImg =
    'https://http2.mlstatic.com/storage/sc-seller-journey-backoffice/images-assets-test/228373172173-errorAvatar.png';

  const handleStateLogin = () => {
    setTimeout(() => {
      setLogout(!logout);
    }, 100);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleStateLogin();
    }
  };

  const handleLogout = () => {
    window.location.href = handlerLogout(platform?.siteId, fullUrl);
  };

  const onClickEvent = (events) => {
    const newEvents = events;
    newEvents.map((event) => {
      if (event.type === 'sc_tracking') {
        event.data.data.eventData = {
          ...event.data.data.eventData,
          previous_page: fullUrl,
        };
      }
      if (event.type === 'redirect') {
        event.data = {
          ...event.data,
          target: '_blank',
        };
      }
      return newEvents;
    });
    if (performEvent && newEvents) {
      newEvents.forEach((event) => performEvent(event));
    }
  };

  if (!auth) {
    return (
      <>
        <div className={`${className}-div`}>{'\u00A0'}</div>
        <div className={`${className}-login-not`}>
          <Button
            className={`${className}-login-not-button`}
            href={handlerLogin(platform.siteId, fullUrl)}
          >
            {cta}
            {/* old-header */}
            <Icon
              containerClassName={['user-icon']}
              type="User24"
              color="#000"
            />
          </Button>
        </div>
      </>
    );
  }

  const iconRender = (
    <>
      {image ? (
        <Image
          className="user-container-icon borderImage"
          alt={image?.alt || 'avatar'}
          src={image?.href}
          srcFallback={errorImg}
        />
      ) : (
        <Icon
          containerClassName={['user-container-icon']}
          color="rgba(0, 0, 0, 0.55);"
          type={icon || 'User16'}
        />
      )}
      {image && image?.alt?.length > 7
        ? `${image?.alt.substring(0, 8)}...`
        : image?.alt}
      <Icon
        color="rgba(0, 0, 0, 0.55);"
        type={logout ? 'ChevronUp12' : 'ChevronDown12'}
      />
    </>
  );

  return (
    <>
      <div className={`${className}-div`}>{'\u00A0'}</div>
      <div
        className={`${className}-login`}
        role="button"
        onClick={logout ? null : () => handleStateLogin()}
        onKeyDown={(e) => handleKeyPress(e)}
        tabIndex={0}
      >
        {nickName ? (
          <>
            <div className="user-container-icon nickname">{nickName}</div>
            {userName && userName?.length > 7
              ? `${userName?.substring(0, 8)}...`
              : userName}
            <Icon
              color="rgba(0, 0, 0, 0.55);"
              type={logout ? 'ChevronUp12' : 'ChevronDown12'}
            />
          </>
        ) : (
          iconRender
        )}
        {/* old-header */}
        <Icon
          containerClassName={['user-container-icon-old']}
          color="#000"
          type={icon || 'UserLogged'}
        />
        {logout &&
          (auth ? (
            <FloatingMenu
              className={`${className}-floating-menu`}
              aria-label="menu"
              id="floating-menu"
              width={210}
              height={240}
              defaultOpen
              trigger={<div className="custom-trigger" />}
              onClose={() => handleStateLogin()}
            >
              <FloatingMenuGroup className={`${className}-floating-menu-group`} label={labelNavigation}>
                {navigation?.map((item) => (
                  <FloatingMenuItem
                    key={item?.title}
                    primary={item?.title}
                    value={item?.title}
                    onSelect={() => onClickEvent(item?.events)}
                  >
                    {item?.pill && (
                      <Pill type={item?.color} size="small">
                        {item?.pill}
                      </Pill>
                    )}
                  </FloatingMenuItem>
                ))}
              </FloatingMenuGroup>
              <FloatingMenuGroup className={`${className}-floating-menu-logout`} label="">
                <FloatingMenuItem
                  primary={cta}
                  value={cta}
                  onSelect={() => handleLogout()}
                />
              </FloatingMenuGroup>
            </FloatingMenu>
          ) : (
            <Button className="logout-button" href={handlerLogout(platform?.siteId, fullUrl)}>
              {cta}
            </Button>
          ))}
      </div>
    </>
  );
};

User.defaultProps = {
  className: 'user-search-container',
};

User.propTypes = {
  className: PropTypes.string,
  cta: PropTypes.string,
  icon: PropTypes.string,
  nickName: PropTypes.string,
  image: PropTypes.shape({
    href: PropTypes.string,
    alt: PropTypes.string,
  }),
  userName: PropTypes.string,
  labelNavigation: PropTypes.string,
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
    }),
  ),
  performEvent: PropTypes.func,
};

export default User;
