import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@andes/typography';
import { Card, CardContent } from '@andes/card';
import TracksContext from '../../context/tracks-context';

const CtaNewNotes = (props) => {
  const { title, primaryAction } = props;
  const { trackMelidata } = useContext(TracksContext.Consumer);

  const ctaTrack = () => {
    if (primaryAction && primaryAction.track && primaryAction.track.data) {
      trackMelidata(
        primaryAction.track.data.path,
        primaryAction.track.data.eventData,
      );
    }
  };

  return (
    <Card className="cta-new-note-container">
      <CardContent className="cta-new-note-content">
        <Text className="cta-new-note-title">{title}</Text>
        <button className="cta-new-note-button" onClick={() => ctaTrack()}>
          <a className="cta-new-note-button-text" href={primaryAction.href}>
            {primaryAction.title}
          </a>
        </button>
      </CardContent>
    </Card>
  );
};

CtaNewNotes.propTypes = {
  title: PropTypes.string,
  primaryAction: PropTypes.shape({
    title: PropTypes.string,
    href: PropTypes.string,
    track: PropTypes.shape({
      data: PropTypes.shape({
        path: PropTypes.string,
        eventData: PropTypes.shape({}),
      }),
    }),
  }),
};

export default CtaNewNotes;
