import Head from 'nordic/head';
import React from 'react';

export const SiteFavicon = () => (
  <Head>
    <link
      rel="icon"
      href="https://http2.mlstatic.com/frontend-assets/ml-web-navigation/ui-navigation/5.21.22/mercadolibre/favicon.svg"
    />
    <meta name="google" content="notranslate" />
  </Head>
);
