import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import TracksContext from '../../context/tracks-context';

const { Tag } = require('@andes/tag');

const CategoriesNote = props => {
  const { brick } = props;
  const { trackMelidata } = useContext(TracksContext.Consumer);

  const onNavigateCategorie = item => {
    if (item.data.tracks && item.data.tracks.length > 0) {
      item.data.tracks.map(trackItem => trackMelidata(trackItem.data.path, trackItem.data.eventData));
    }
    window.location.href = (item.data.href);
  };

  return (
    <div className="tagsCategories">
      {brick.bricks?.map(item => (
        <Tag
          onClick={() => onNavigateCategorie(item)}
          label={item.data.text}
          key={`idCategorie-${item.id}`}
          className={`group-list-tag_${(item.data.modifier || '').replace('#', '')}`}
        />
      ))}
    </div>
  );
};

CategoriesNote.propTypes = {
  brick: PropTypes.shape({
    bricks: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default CategoriesNote;
