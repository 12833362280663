import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from '@andes/list';
import Icon from '../Icon';

const ListSites = (props) => {
  const { data } = props;

  if (data === undefined) return null;

  return (
    <List className="su-list-content-sites" srLabel="Lista de sitios disponibles">
      {data.map(({ key, text }) => (
        <ListItem key={`tootip-su-list${key}`} disabled>
          <Icon type={key} containerClassName={['su-icon-site']} />
          {text}
        </ListItem>
      ))}
    </List>
  );
};
ListSites.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ListSites;
