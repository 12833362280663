import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { SearchBox } from '@andes/search-box';
import { Form } from '@andes/form';
import { Modal } from '@andes/modal';
import { useSelector } from 'react-redux';
import { handlerRedirect } from '../../helpers/searchRedirect';
import { getContext } from '../../helpers/selectors';
import { NAME_CONFIG_KEYS } from '../../helpers/constants';
import { trackingEvent } from '../../flox/custom-events/track';

const SEARCHPATH = /^\/(learning-center\/)?search.*/;

const Search = (props) => {
  const { placeholder, searchButton, track, performEvent } = props;
  const { auth, isMobile, platform, device, suScope } = useSelector(getContext);
  const [path, setPath] = useState('');
  const [valueInputSearch, setValueInputSearch] = useState('');
  const [searchStatus, setSearchStatus] = useState(false);
  const searchRef = useRef(null);
  const searchClass = searchStatus ? 'sc-remove-button' : '';

  const isSearchPath = (p) => RegExp(SEARCHPATH).test(p);

  const searchIsWebview = (actualPath) => {
    const newUrl = `meli://webview/?url=${encodeURIComponent(
      actualPath.toString(),
    )}&webkit-engine=2`;
    if (isSearchPath(path.pathname)) {
      const loadingEvent = {
        id: 'loading',
        uiType: 'loading',
        data: {
          loadingStatus: true,
          modifier: 'fullscreen',
          size: 'large',
        },
      };
      [loadingEvent].forEach((e) => {
        performEvent(e);
      });
      const options = {
        replace: true,
        transition: 'push',
      };
      return MobileWebKit.navigation.push(newUrl, options);
    }
    return MobileWebKit.navigation.push(newUrl);
  };

  const changeQuery = (value) => {
    const actualPath = new URL(window.location.href);
    setPath(actualPath);
    actualPath.searchParams.set('q', value.trim());
    actualPath.searchParams.set('offset', 0);
    actualPath.searchParams.set('limit', 18);
    actualPath.searchParams.delete('filters');
    actualPath.searchParams.delete('page');
    window.dispatchEvent(new Event('locationchange'));
    if (value === '' || value === null) actualPath.searchParams.delete('q');
    const requestBrick = {
      type: 'request',
      data: {
        key: NAME_CONFIG_KEYS.SEARCH_CARD,
        arguments: actualPath.search,
      },
    };

    if (isMobile) {
      if (device?.webView) {
        searchIsWebview(actualPath);
      } else {
        window.location.href = actualPath.toString();
      }
    } else {
      performEvent(requestBrick);
      window.history.pushState({}, '', actualPath.toString());
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setValueInputSearch(e.target.value);
    if (e.target.localName === 'svg' || e.target.localName === 'path') {
      /* istanbul ignore next */
      if (isSearchPath(path.pathname)) {
        changeQuery('');
        setTimeout(() => {
          setValueInputSearch('');
        }, 250);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (valueInputSearch !== null) {
      if (track) {
        const searchTrack = track;
        searchTrack.data.eventData.text = valueInputSearch;
        trackingEvent({ eventData: searchTrack });
      }
      if (isSearchPath(path.pathname)) {
        changeQuery(valueInputSearch);
      } else {
        window.location.href = handlerRedirect(
          suScope,
          platform.siteId,
          platform.countryId,
          valueInputSearch,
          isMobile,
        );
      }
      setSearchStatus(false);
      if (document.getElementById('search-box')) document.getElementById('search-box').blur();
      if (performEvent) {
        const requestBrick = [
          {
            type: 'update_bricks',
            data: {
              bricks: [
                {
                  id: 'loading_desktop' || 'loading_search_cards_mobile',
                  uiType: 'loading',
                  data: {
                    loadingStatus: true,
                    modifier: 'inline',
                    size: 'medium',
                  },
                },
              ],
            },
          },
        ];
        requestBrick.forEach((event) => performEvent(event));
      }
    }
  };

  const handleSearchState = () => {
    setSearchStatus(false);
  };

  const handleClear = () => {
    if (isSearchPath(path.pathname)) changeQuery('');
  };

  useEffect(() => {
    const initPath = new URL(window.location.href);
    setPath(initPath);
    const query = initPath.searchParams.get('q');
    if (query !== '') {
      setValueInputSearch(query);
    }
  }, []);

  const classNameDefault = `search-form${auth ? '' : '-not'}`?.trim();
  if (isMobile) {
    return (
      <>
        <Form className="search-form" onSubmit={handleSubmit}>
          <SearchBox
            srLabel={placeholder}
            className={`search-box${auth && '-extended'}`}
            placeholder={placeholder}
            value={
              valueInputSearch
                ? valueInputSearch?.charAt(0)?.toUpperCase() +
                  valueInputSearch?.slice(1)
                : ''
            }
            onChange={handleChange}
            inputProps={{
              id: 'search-box',
              onFocus: () => setSearchStatus(true),
            }}
          />
          <button
            className={`sc-cancel-button ${searchClass}`}
            onClick={() => {
              setValueInputSearch('');
              setSearchStatus(false);
            }}
          >
            {searchButton}
          </button>
        </Form>
        <div className="sc-modal-search" ref={searchRef} />
        <Modal
          isOpen={searchStatus}
          onClose={handleSearchState}
          type="full"
          container={searchRef.current}
        />
      </>
    );
  }

  return (
    <Form className={classNameDefault} onSubmit={handleSubmit}>
      <SearchBox
        srLabel={placeholder}
        className={`search-box${auth && '-extended'}`}
        placeholder={placeholder}
        value={
          valueInputSearch
            ? valueInputSearch?.charAt(0)?.toUpperCase() +
              valueInputSearch?.slice(1)
            : ''
        }
        onChange={handleChange}
        onClear={handleClear}
      />
    </Form>
  );
};

Search.propTypes = {
  placeholder: PropTypes.string,
  searchButton: PropTypes.string,
  track: PropTypes.shape({
    data: PropTypes.shape({
      path: PropTypes.string,
      eventData: PropTypes.shape({}),
    }),
  }),
  performEvent: PropTypes.func,
};

export default Search;
