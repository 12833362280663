/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button, ButtonText } from '@andes/button';
import { Text } from '@andes/typography';
import { ListItem } from '@andes/list';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Icon from '../Icon';
import FeedBackMessageView from '../FeedBackMessageView';
import TracksContext from '../../context/tracks-context';
import { getContext, getNoteData } from '../../helpers/selectors';

// UI
const { RadioList } = require('@andes/radio-list');
const restclient = require('nordic/restclient');
const config = require('../../../config/restclient/browser');

const FeedBack = props => {
  const { title, brick, track } = props;
  const [isLikeChecked, setIsLikChecked] = useState(null);
  const { auth, fullUrl } = useSelector(getContext);
  const { parentNoteId } = useSelector(getNoteData);
  const [isEnabledClick, setIsEnabledClick] = useState(true);
  const [optionsReason, setOptionsReason] = useState([]);
  const [selectOptionReason, setSelectOptionReason] = useState('');
  const [titleReason, setTitleReason] = useState('');
  const [descriptionReason, setDescriptionReason] = useState('');
  const [infoOtherReason, setInfoOtherReason] = useState({});
  const [isRepliedFeedback, setIsRepliedFeedback] = useState(false);
  const [reasonInputValue, setReasonInputValue] = useState('');
  const reasonsEndRef = useRef(null);
  const { trackMelidata } = useContext(TracksContext.Consumer);

  useEffect(() => {
    if (isLikeChecked !== null) {
      const filterOptionsReason = brick.bricks
        ? brick.bricks.find(item => item.uiType === (isLikeChecked === false ? 'feedback_dislike' : 'feedback_like'))
        : '';
      if (filterOptionsReason && filterOptionsReason.data && filterOptionsReason.data.optionsFeedback) {
        setOptionsReason(filterOptionsReason.data.optionsFeedback);
        setTitleReason(filterOptionsReason.data.title);
        setTimeout(() => {
          reasonsEndRef?.current?.scrollIntoView(false);
        }, 10);
      } else {
        setTitleReason(filterOptionsReason.data.titleSuccess);
        setDescriptionReason(filterOptionsReason.data.descriptionSuccess);
        setOptionsReason([]);
      }
    } else {
      const foundIndex = brick.bricks.findIndex(item => item.data.like === true);
      setIsEnabledClick(foundIndex === -1);
    }
  }, [brick.bricks, isLikeChecked]);


  const handerLike = uiType => {
    if (isEnabledClick) {
      if (uiType !== 'feedback_like') {
        setIsLikChecked(false);
        return;
      }
      setIsLikChecked(true);
      setIsRepliedFeedback(true);
      if (auth?.user?.id) {
        const request = restclient(config(fullUrl));
        request
          .post('/note/feedback-note', {
            data: {
              noteId: parentNoteId,
              like: true,
            },
          });
      }
      trackMelidata(track.data.path, {
        ...track.data.eventData,
        like: true,
      });
    }
  };

  const savingReason = () => {
    setIsLikChecked(false);
    setIsRepliedFeedback(true);
    const request = restclient(config(fullUrl));
    if (auth?.user?.id) {
      request
        .post('/note/feedback-note', {
          data: {
            noteId: parentNoteId,
            like: false,
            dislikeId: selectOptionReason,
            dislikeReason: reasonInputValue,
          },
        })
        .then(() => {});
    }
    const getBrickDesLikeReason = brick.bricks ? brick.bricks.find(item => item.uiType === 'feedback_dislike') : '';
    setTitleReason(getBrickDesLikeReason?.data?.titleSuccess);
    setDescriptionReason(getBrickDesLikeReason?.data?.descriptionSuccess);
    // eslint-disable-next-line no-unused-expressions
    reasonInputValue !== ''
      ? trackMelidata(track.data.path, {
        ...track.data.eventData,
        like: false,
        reason_id: selectOptionReason,
        reason: reasonInputValue,
      }) :
      trackMelidata(track.data.path, {
        ...track.data.eventData,
        like: false,
        reason_id: selectOptionReason,
      });
  };

  const handlerChangeOptionReason = optionReason => {
    setInfoOtherReason({});
    const getBrickDesLikeReason = brick.bricks ? brick.bricks.find(item => item.uiType === 'feedback_dislike') : '';
    if (getBrickDesLikeReason) {
      // eslint-disable-next-line max-len
      const getBrickReason = getBrickDesLikeReason.bricks
        ? getBrickDesLikeReason.bricks.find(item => item.uiType === 'feedback_reason')
        : '';
      if (getBrickReason && getBrickReason.data) {
        setInfoOtherReason(getBrickReason.data);
      }
    }
    setSelectOptionReason(optionReason);
  };

  const determinateColor = item => {
    if (isLikeChecked === null) {
      // eslint-disable-next-line default-case
      switch (item.uiType) {
        case 'feedback_like':
          if (item.data?.like) {
            return ' color-green';
          }
          break;

        case 'feedback_dislike':
          if (item.data?.like) {
            return ' color-red';
          }
          break;
      }
    }
    if (isLikeChecked && item.uiType === 'feedback_like') {
      return ' color-green';
    }
    if (isLikeChecked === false && item.uiType === 'feedback_dislike') {
      return ' color-red';
    }
    return '';
  };

  if (isRepliedFeedback) {
    // eslint-disable-next-line max-len
    return <FeedBackMessageView title={titleReason} message={descriptionReason} />;
  }

  return (
    <div className="container-feedback">
      <Text className="note-subtitle" component="p" size="l">
        {title}
      </Text>

      <div>
        {brick.bricks.map(item => (
          <Button
            key={`feedbackAction-${item.id}`}
            disabled={item.data?.like || !isEnabledClick}
            className={`btn-action-feedback${determinateColor(item)}`}
            onClick={() => handerLike(item.uiType)}
          >
            <Icon
              color={
                (isLikeChecked && item.uiType === 'feedback_like') ||
                (isLikeChecked === false && item.uiType === 'feedback_dislike') ||
                item.data?.like
                  ? 'white'
                  : 'black'
              }
              type={item.uiType === 'feedback_dislike' ? 'DisLike' : 'Like'}
            />
          </Button>
        ))}
      </div>

      <div ref={reasonsEndRef}>
        {titleReason && (
          <Text className="title-reason" component="h4" type="title" size="l">
            {titleReason}
          </Text>
        )}
        <RadioList
          name="uncontrolled-radio-reasons"
          id="uncontrolled-radio-list-reasons"
          srLabel="radio-list-reasons"
          onClick={(e, itemValue) => handlerChangeOptionReason(itemValue)}
        >
          {optionsReason.map(item => (
            <ListItem key={`itemReason-${item.id}`} title={item.text} value={item.id} />
          ))}
        </RadioList>
        {infoOtherReason &&
          infoOtherReason.inputPlaceholder &&
          selectOptionReason &&
          selectOptionReason === 'OTHER_REASON' && (
            <textarea
              className="text-area-reason"
              onChange={e => setReasonInputValue(e.target.value)}
              placeholder={infoOtherReason.inputPlaceholder}
            />)}

        {selectOptionReason && (
          <Button
            aria-describedby="btn-send-feedback-text"
            className="btn-send-feedback"
            hierarchy="loud"
            size="large"
            onClick={() => savingReason()}
          >
            <ButtonText>{infoOtherReason.btnPlaceholder}</ButtonText>
          </Button>
        )}
      </div>
    </div>
  );
};

FeedBack.propTypes = {
  brick: PropTypes.shape({
    bricks: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  title: PropTypes.string,
  track: PropTypes.shape({
    data: PropTypes.shape({
      path: PropTypes.string,
      eventData: PropTypes.shape({}),
    }),
  }),
};

export default FeedBack;
