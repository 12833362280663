/* eslint-disable nordic/no-img-tags */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Title } from '@andes/typography';
import { Thumbnail } from '@andes/thumbnail';
import Icon from '../../Icon';
import Tag from '../../Tag';
import IconText from '../../IconText';
import Fallback from '../../Fallback';
import { checkSuScope } from '../../../helpers/checkSuScope';
import { getContext } from '../../../helpers/selectors';

const SimpleRow = (props) => {
  const { dataItem, bricks, performEvent } = props;
  const { title, image, label, description, chevron, modifier, fallback } =
    dataItem;
  const { suScope, device: { webView } } = useSelector(getContext);

  const webViewEvent = [
    {
      type: 'sc_deeplink',
      data: {
        url: chevron?.link,
      },
    },
  ];

  const onClickEvent = () => {
    if (performEvent) {
      webViewEvent.forEach(event => performEvent(event));
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onClickEvent();
    }
  };


  return (
    <a href={checkSuScope(chevron?.link, suScope)}>
      <div
        className="content-simple-row-guie"
        role="button"
        tabIndex={0}
        onKeyDown={webView ? (e) => handleKeyPress(e) : null}
        onClick={webView ? () => onClickEvent() : null}
      >
        <div className="row-guie-label">
          <Title component="h1" size="xs" color={modifier || 'secondary'}>
            {label}
          </Title>
        </div>
        {fallback && (
          <Fallback {...fallback} className="sc-fallback-simple-row" />
        )}
        {!fallback && (
          <>
            <Thumbnail size="56" className={`border-avatar-${modifier || ''}`}>
              <img className="avatar" alt="avatar" src={image} />
            </Thumbnail>
            <div className="content-row-simple-description">
              <Title className="row-guie-title" component="h1" size="m">
                {title}
              </Title>
              <p className="row-guie-description">{description}</p>
              <div className="row-tag-icon-container">
                {bricks?.map((item) => (
                  // eslint-disable-next-line max-len, implicit-arrow-linebreak
                  <>
                    {item.uiType === 'icon_text' && (
                      <IconText
                        styles={{ marginRight: 12 }}
                        color="grey"
                        icon={item?.data.icon}
                        label={item?.data.label}
                      />
                    )}
                    {item.uiType === 'tag' && <Tag {...item.data} />}
                  </>
                ))}
              </div>
            </div>
          </>
        )}
        {chevron && (
          <Icon
            containerClassName={['icon-chevron']}
            type={chevron.icon}
            color="rgba(0, 0, 0, 0.9)"
          />
        )}
      </div>
    </a>
  );
};

SimpleRow.defaultProps = {
  dataItem: {},
  bricks: [],
};

SimpleRow.propTypes = {
  dataItem: PropTypes.shape({
    title: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    modifier: PropTypes.string,
    chevron: PropTypes.shape({
      icon: PropTypes.string,
      link: PropTypes.string,
    }),
    fallback: PropTypes.shape({}),
  }),
  bricks: PropTypes.shape([]),
  performEvent: PropTypes.func,
};

export default SimpleRow;
